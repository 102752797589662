.login-btn {
    display: flex;
    justify-content: first baseline;
    margin-top: 30px;
}

.sync_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.captcha_input {
    margin-top: 8px;
}