.user_profile {
	padding-right: 50px;
}
.profile_icon {
	height: 50px;
	width: 50px;
	max-height: 70px;
	max-width: 70px;
	border-radius: 50%;
}
::-webkit-scrollbar {
	width: 0px;
}
.badge-title {
	text-align: "center";
	font-size: 20;
}
.badge-body {
	text-align: "center";
	font-size: 20;
	color: black;
}
.user_img{
	cursor:pointer !important;
}
.user_name{
	cursor:default !important;
}
.navbar-collapse{
	margin-top: 0px !important;
}