.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}
.middle svg{
 transform: none !important;
}

.para_desc{
  text-align: center;
}
.otp_input{
  background-color: rgba(238, 236, 236, 0.152);
  height: auto;
}
.otp_fields{
    border-radius: 12px;
    background-color:#8574df42;
    width: 2.3rem !important;
    height: 2.3rem;
    margin: 0 5px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    /* flex-wrap: wrap; */
}
.resend_otp_container{
  margin-top: 1rem;
}
.otp-sbmt-btn {
  padding-left: 9rem;
  padding-top: 2rem;
  width: 10rem;
}
.center_align_elmnt {
    display: flex;
    justify-content: center;
}